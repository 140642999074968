import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import MainLayout from "../layout/MainLayout";

const VideoporterosPage = ({ }) => {

    const imageSubtitle = (subtitle: string) => <span className="w-full font-light text-sm text-center mt-2">{subtitle}</span>;

    return (
        <MainLayout title="Videoporteros inteligentes">

            <div className="grid grid-flow-row grap-4">

                <div className="pt-2 pb-8 text-center">
                    <h1 className="text-4xl my-2">Videoporteros inteligentes de MOBOTIX&copy;</h1>

                    <StaticImage
                        src="../images/t26.jpg"
                        alt="Videoportero inteligente T26"
                        className="h-72 rounded ring-2 ring-gray-500 ring-opacity-25"
                        placeholder="blurred"
                    />
                    <span className="w-full font-light text-sm mx-auto">Videoportero T26 de MOBOTIX&copy;</span>
                </div>

                <hr />

                <div className="flex flex-col py-4">

                    <div className="w-full py-2">
                        <h2 className="text-3xl py-2">Cámara omnidireccional</h2>
                        <p className="whitespace-pre-line">
                            El videoportero T26 cuenta con una <em>cámara hemisférica</em> con un sensor de 6 megapíxeles que puede capturar un espacio de 180º; de pared a pared y del techo al suelo, para que no haya <strong>ningún punto ciego</strong>.
                        </p>
                    </div>

                    <div className="flex flex-row">

                        <div className="w-1/2 flex flex-col mr-2">
                            <StaticImage
                                src="../images/T26_Camara_180deg_Panorama.png"
                                alt="Videoportero T25"
                                className="h-36 rounded ring-2 ring-gray-500 ring-opacity-25"
                                placeholder="blurred"
                            />
                            {imageSubtitle("Visión 180º Panorámica")}
                        </div>

                        <div className="w-1/2 flex flex-col ml-2">
                            <StaticImage
                                src="../images/T26_Camara_360deg_Full.png"
                                alt="Videoportero T25"
                                className="h-36 rounded ring-2 ring-gray-500 ring-opacity-25"
                                placeholder="blurred"
                            />
                            {imageSubtitle("Visión 360º")}
                        </div>

                    </div>

                </div>

                <hr />

                <div className="flex flex-col py-4">

                    <h2 className="text-3xl py-2">Observa desde cualquier parte del mundo</h2>

                    <div className="flex flex-row">

                        <div className="w-1/2 flex flex-col mr-2">

                            <div className="whitespace-pre-line">
                                <p>Puedes acceder a las funcionalidades del videoportero desde donde quieras y cuando quieras con la aplicación móvil.</p>
                                <p>¡Cuando alguien llame a través del videoportero recibirás una notificación en tu teléfono móvil! Podrás <em>ver a través de la cámara</em>, <em>comunicarte por voz</em> y <em>abrir la puerta</em> <strong>desde cualquier parte del mundo</strong>.</p>
                            </div>
                        </div>

                        <div className="w-1/2 flex flex-col ml-2">
                            <StaticImage
                                src="../images/t26_app.png"
                                alt="Observa desde cualquier lugar del mundo con la aplicación móvil"
                                className="h-36 rounded ring-2 ring-gray-500 ring-opacity-25"
                                placeholder="blurred"
                            />
                            {imageSubtitle("Acceso a través de la aplicación móvil")}
                        </div>

                    </div>

                </div>

            </div>

        </MainLayout >
    );
};

export default VideoporterosPage;